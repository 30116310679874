import { redirect, RouteObject } from 'react-router-dom';
import { UserPermissions } from '../../components/restricted';
import { fromRestrictedRouteObjects } from '../../components/restricted/routing';

import RekvisitionScreen from './rekvisition-screen';
import SeeRekvisitionScreen from './see-rekvisition-screen';
import RekvisitionUpdateScreen from './rekvisition-update-screen';
import Http404 from '../http/404';
import Api from '../../shared/networking/api';

const RekvisitionRouterObjects: RouteObject[] = fromRestrictedRouteObjects([
    {
        index: true,
        element: <RekvisitionScreen />,
        permissions: UserPermissions.Rekvisition,
    },
    {
        path: 'Create',
        loader: async ({ params: { id } }) => {
            const response = await Api.post<{ id: number }[]>(`/Finans/rekvisition?enhedsys=${id}`);
            if (!Api.ok(response)) {
                return redirect(`/${id}/Rekvisition?error=Kunne ikke oprette ny rekvisition`);
            };
            return redirect(`/${id}/Rekvisition/${response.data[0].id}/update`);
        }
    },
    {
        path: ':rekvisitionId',
        element: <SeeRekvisitionScreen />,
        permissions: UserPermissions.Rekvisition,
    },
    {
        path: ':rekvisitionId/update',
        element: <RekvisitionUpdateScreen />,
        permissions: UserPermissions.Rekvisition_Update_Fields,
    },
    {
        path: '*',
        element: <Http404 />,
    },
]);

export default RekvisitionRouterObjects;
