import AutoForm, { IAutoFormHandle, IAutoFormProps, ItemTypes } from '../../components/auto-form/v1';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Button as DxButton } from 'devextreme-react/button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';
import { useEffect, useRef } from 'react';
import { CUSTOMER_LOGO_URL } from '../../config';
import Api from '../../shared/networking/api';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import { useParams } from 'react-router-dom';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import { useQueryParamsList } from '../../shared/hooks/use-query-param-state';
import { formatFakturaAdresse, formatLev, formatLeveres, formatRekvirent, RekvData } from './see-rekvisition-screen';
import useCTNavigate from '../../shared/hooks/use-ct-navigate';

const RekvisitionUpdateScreen = () => {
    const navigate = useCTNavigate();
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const enhedid = useEnhedId();
    const { rekvisitionId } = useParams<{ rekvisitionId: string }>();
    const { param: readonly } = useQueryParamsList('readonly');
    const [_data, isLoading, isError] = useFetch<[RekvData]>(`/Finans/RekvPrint?rekvsys=${rekvisitionId}`);
    const data = _data?.[0];

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Kunne ikke hente rekvisition, prøv igen senere', { variant: 'error' });
            navigate(`/rekvisition/${rekvisitionId}`);
        }
    }, [isError]);

    const formRef = useRef<IAutoFormHandle>(null);

    const submit = () => formRef.current?.submit();

    const isReadonly = (key: string) => readonly.includes(key);

    const handleSubmit: IAutoFormProps['onSubmit'] = async (data) => {

        // TODO: Add rekv id to put
        const response = await Api.put(`/Finans/rekvisition?rekvsys=${rekvisitionId}`, data);
        if (!Api.ok(response)) {
            enqueueSnackbar('Kunne ikke opdatere rekvisition, prøv igen senere', { variant: 'error' });
        } else {
            enqueueSnackbar('Rekvisition opdateret', { variant: 'success' });
            navigate(`/rekvisition/${rekvisitionId}`);
        }
    };

    if (isLoading || isError) return <LoadingScreen />;

    return (
        <Container sx={{ pt: 2, '& label': { textAlign: 'left', fontWeight: 600 } }}>
            <Grid container>
                <Grid item xs={1}>
                    <DxButton key='undefined-period-btn' height='38px' icon='arrowleft' onClick={() => navigate(`/Rekvisition/${rekvisitionId}`)} />
                </Grid>
                <Grid item xs={11}>
                    <Typography variant='h4'>Rediger rekvisition</Typography>
                </Grid>
            </Grid>
            <img src={CUSTOMER_LOGO_URL} alt='Firma logo til rekvisition' />
            <AutoForm
                ref={formRef}
                useDxForm
                onSubmit={handleSubmit}
                onCancel={() => navigate(`/Rekvisition/${rekvisitionId}`)}
                buttons={[]}
                colCount={2}
                grupper={[
                    {
                        id: 1,
                        items: [
                            {
                                id: 'litra',
                                type: ItemTypes.Lookup,
                                props: {
                                    colSpan: 2,
                                    lookupProps: {
                                        url: '/Finans/Lookup/Litra',
                                    },
                                },
                                value: data['litra'],
                            },
                            {
                                id: 'rekvisitionTekst',
                                props: {
                                    colSpan: 2,
                                },
                                value: data['rekvisitionTekst'],
                            },
                            {
                                id: 'rekvisitionsDato',
                                type: ItemTypes.Date,
                                value: data['rekvisitionsDato'],
                                readonly: true
                            },
                            {
                                id: 'rekvirent',
                                value: formatRekvirent(data).replace('\n', ' '),
                                readonly: true
                            },
                            {
                                // Større lookup med sortering og mulighed for "favorit" felt i lookup data
                                id: 'levId',
                                description: 'Til',
                                type: ItemTypes.Block,
                                value: formatLev(data),
                                readonly: isReadonly('levId'),
                            },
                            {
                                id: 'fakturaadr',
                                type: ItemTypes.Block,
                                description: 'Fakturaadr.',
                                value: formatFakturaAdresse(data),
                                readonly: true
                            },
                            {
                                id: 'leveres',
                                type: ItemTypes.Block,
                                value: formatLeveres(data),
                                readonly: true,
                            },
                            {
                                id: 'konto',
                                type: ItemTypes.Lookup,
                                props: {
                                    lookupProps: {
                                        url: `/Finans/Lookup/Konto?enhedsys=${enhedid}`,
                                    },
                                },
                                value: data['konto'],
                                readonly: true,
                            },
                            {
                                id: 'leveresKontaktperson',
                                description: 'Knt.person',
                                type: ItemTypes.Lookup,
                                props: {
                                    lookupProps: {
                                        url: `/Finans/Lookup/kontaktPerson?enhedsys=${enhedid}`,
                                        acceptCustomValue: true,
                                    },
                                },
                                value: data['leveresKontaktperson'],
                            },
                            {
                                id: 'aftaltBeloeb',
                                description: 'Aftalt beløb',
                                type: ItemTypes.Decimal,
                                value: data['aftaltBeloeb'],
                            },
                            {
                                id: 'leveresTidspunkt',
                                description: 'Lev.tidspunkt',
                                value: data['leveresTidspunkt'],
                            },
                            {
                                id: 'files',
                                description: 'Bilag',
                                type: ItemTypes.File,
                            },
                        ].reverse(),
                    },
                    {
                        id: 2,
                        items: [
                            {
                                id: 'rekvisitionNote',
                                description: 'Note',
                                props: {
                                    colSpan: 2,
                                },
                                type: ItemTypes.HtmlBlock,
                                value: data['rekvisitionNote'],
                            },
                        ],
                    },
                ].reverse()} // For some reason these were rendered in reverse order
            />
            <Button color='success' sx={{ mt: 2 }} onClick={submit}>
                Gem ændringer
            </Button>
        </Container>
    );
};

export default RekvisitionUpdateScreen;
