import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import BoltIcon from '@mui/icons-material/Bolt';
import { ReactElement } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavLink } from 'react-router-dom';
import { useEnhedId } from '../../../shared/hooks/use-enhed-id';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Restricted, { UserPermissions } from '../../../components/restricted';
import BugReportIcon from '@mui/icons-material/BugReport';


const BigButton = ({
    children,
    onClick,
    style,
}: {
    children: ReactElement | string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    style?: React.CSSProperties;
}) => {
    return (
        <Button sx={{ width: '150px', height: '150px', borderRadius: '8px !important', ...style }} onClick={onClick}>
            {children}
        </Button>
    );
};

export const Tile = ({
    text,
    icon,
    to,
    permissions,
}: {
    text: string;
    icon: ReactElement;
    to: string;
    permissions?: UserPermissions | UserPermissions[];
}) => {
    return (
        <Restricted permissions={permissions ?? []}>
            <Grid item xs={'auto'}>
                <NavLink to={to}>
                    <BigButton onClick={() => { }}>
                        <Grid container className='w-full' spacing={2}>
                            <Grid item xs={12}>
                                {icon}
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{ fontSize: '17px' }}>{text}</p>
                            </Grid>
                        </Grid>
                    </BigButton>
                </NavLink>
            </Grid>
        </Restricted>
    );
};

const OverviewTilesScreen = () => {
    const enhId = useEnhedId();
    const context = 'Context';

    return (
        <Box height='100%' width='100%' padding='40px'>
            <Grid container justifyContent={'center'} spacing={2}>
                <Tile
                    text='Drift'
                    icon={<ListAltIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Drift${window.location.search}`}
                    permissions={UserPermissions.Drift}
                />
                <Tile
                    text='Syn'
                    icon={<AssignmentIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Syn${window.location.search}`}
                    permissions={UserPermissions.Syn}
                />
                <Tile
                    text='Bygnings-delskort'
                    icon={<MapsHomeWorkIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Bygningsdelskort${window.location.search}`}
                    permissions={UserPermissions.Bygningsdelskort}
                />
                <Tile
                    text='Forsyning'
                    icon={<BoltIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Forsyning${window.location.search}`}
                    permissions={UserPermissions.Forsyning}
                />
                <Tile
                    text='Service'
                    icon={<HomeRepairServiceIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Service${window.location.search}`}
                    permissions={UserPermissions.Service}
                />
                <Tile
                    text='Stamdata'
                    icon={<DocumentScannerIcon fontSize='large' />}
                    to={`/${enhId}/Overview/${context}/Stamdata${window.location.search}`}
                    permissions={UserPermissions.Overview}
                />
                <Tile
                    text='Debug'
                    icon={<BugReportIcon fontSize='large' />}
                    to={`Debug${window.location.search}`}
                    permissions={UserPermissions.Admin}
                />
            </Grid>
        </Box>
    );
};

export default OverviewTilesScreen;
