import usePath from './use-path';

export interface IWhiteTree {
    [key: string | symbol]: IWhiteTreeNode;
}
export type IWhiteTreeNode = boolean | IWhiteTree;

/** key used for default values to avoid collision with string keys */
export const DEFAULT_WHITE_TREE_SYMBOL = Symbol();

/** White tree for whether enhed id is required */
const enhedWhiteTree: IWhiteTreeNode = {
    map: true,
    team: {
        status: false,
        tegninger: false,
        [DEFAULT_WHITE_TREE_SYMBOL]: true,
    },
    test: true,
    admin: true,
    settings: true,
    service: true,
    dataextraction: true,
    forsyningadmin: true,
    overview: true,
};

/**
 * Traverses an IWhiteTree
 *
 * @param tree The tree to traverse
 * @param path The path to traverse the tree with
 * @param defaultKey The key to use for default values. Defaults to the symbol DEFAULT_WHITE_TREE_SYMBOL
 * @returns The value at the end of the path, or the default value if the path is not found. If no default value is provided, returns false
 */
export const traverseWhiteTree = (
    tree: IWhiteTree,
    path: string[],
    defaultKey: string | symbol = DEFAULT_WHITE_TREE_SYMBOL
) => {
    let current: IWhiteTree = tree;
    for (const p of path) {
        const next = current[p];

        if (next === false || next === true) return next;

        if (next === undefined) return current[defaultKey] === true;

        current = next;
    }

    // Reached end of path
    return current[defaultKey] === true;
};

/**
 * Checks if enhed is required for current path according to enhedWhiteTree
 *
 * Tree traversal logic is extracted into traverseWhiteTree
 *
 * @returns true if enhed is required for current path
 */
const useEnhedWhitelist = () => {
    const path = usePath();

    // root is whitelisted
    if (path.length === 0) return true;

    // Traverse tree
    return traverseWhiteTree(
        enhedWhiteTree,
        path.map((s) => s.toLowerCase())
    );
};

export default useEnhedWhitelist;
