import './auto-grid.css';

import { BottomLeftBox, BottomRightBox, CenterCenterBox, ScreenContainer } from '../mui/styled-mui';
import { FormEvent, useContext, useEffect, useId, useRef, useState } from 'react';
import { IAutoFormSubmitType, ItemTypes } from '../auto-form/v1/interfaces-and-defaults';
import { IDictionary, WithOptional } from '../../shared/utils/types';
import Restricted, { usePermissions } from '../restricted';

import Api from '../../shared/networking/api';
import AutoForm from '../auto-form/v1';
import { AutoGridContext } from './context';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CheckIcon from '@mui/icons-material/Check';
import { ClickAwayListener } from '@mui/material';
import Container from '@mui/material/Container/Container';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DragDropUploaderPopup from '../file-management/drag-drop-uploader-popup';
import EditIcon from '@mui/icons-material/Edit';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FormTextField } from '../auto-form/v1/form-items';
import Grid from '@mui/material/Grid/Grid';
import { IGrupper } from '../auto-form/v1';
import { ITimelineItem } from '../data-displaying/timeline/timeline';
import IconButton from '@mui/material/IconButton/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import LoadingScreen from '../../screens/loading';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Popper from '@mui/material/Popper';
import { Popup } from 'devextreme-react/popup';
import PopupForm from './popup-form';
import QRCode from 'react-qr-code';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ReportIcon from '@mui/icons-material/Report';
import { Scrollable } from '../misc/flex';
import Stack from '@mui/material/Stack/Stack';
import TimelinePopup from '../data-displaying/timeline/timeline-popup';
import Typography from '@mui/material/Typography/Typography';
import { UserPermissions } from '../restricted/interfaces';
import UtilsString from '../../shared/utils/utils-string';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';
import WarningIcon from '@mui/icons-material/Warning';
import { ZCommand } from './layout-interface';
import { downloadSVGToUser } from '../../shared/networking/download';
import dxDataGrid from 'devextreme/ui/data_grid';
import { isMobile } from 'react-device-detect';
import printJS from 'print-js';
import styled from '@mui/styles/styled';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import { useEnhed } from '../../shared/hooks/use-enhed';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';
import usePopup from '../../shared/hooks/use-popup';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { ExtendedEnhedList } from '../data-getters/extended-enhed-list';
import { ItemClickEvent } from 'devextreme/ui/list';

//#region General Interfaces / Types

// TODO: Fix any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ICommandProps<TOptions = any, TData = any> extends ZCommand {
    dxProps: IDxRenderProps<TData>;
    options: TOptions;
    closeParent?(): void;
}

export interface IDxRenderProps<T = unknown> extends IDictionary {
    CellElement: Element;
    data: T & { id: number };
    displayValue: string | null;
    component: dxDataGrid;
    isAltRow: boolean;
    isEditing: boolean;
    key: number | string;
    row: {
        cells: [];
        data: T;
        dataIndex: number;
        isEditing: boolean;
        isExpanded: boolean;
        isSelected: boolean;
        key: number;
        loadIndex: number;
        rowIndex: number;
        rowType: rowType;
        values: unknown[];
    };
    rowIndex: number;
    rowType: rowType;
    text: string;
    values: unknown[];
}

type rowType = 'data';

const useCommandUrl = (props: Pick<ICommandProps, 'urlOverride'>) => {
    const context = useContext(AutoGridContext);
    return props.urlOverride ?? context.props.url;
};

//#endregion General Interfaces / Types
//#region Renders

export const CommandIconButton = styled(IconButton)(() => ({
    'width': '32px',
    'height': '32px',
    '& svg': { fontSize: 32 },
}));

//#region Information

export interface ICommandInfo {}

interface ICommandInfoData {
    information?: string; // Url
}

const CommandInfo = (props: ICommandProps<ICommandInfo, ICommandInfoData>) => {
    const { hasPermissions } = usePermissions();
    const url = props.dxProps.data.information;
    const disabled = props.disabled || UtilsString.IsNullOrWhitespace(url);
    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        if (disabled) return;

        window.open(url!, '_blank')?.focus();
    };

    const iconButton = (
        <CommandIconButton
            key={`command-info-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={disabled}
        >
            <InfoIcon key={`command-info-aci1-${props.dxProps.key}`} />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={disabled} key={`command-info-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-info-lii-${props.dxProps.key}`}>
                <InfoIcon key={`command-info-aci2-${props.dxProps.key}`} color={disabled ? undefined : 'primary'} />
            </ListItemIcon>
            <Typography>{hasPermissions(UserPermissions.OK) ? 'Link til OK' : 'Link til EKnet'}</Typography>
        </MenuItem>
    );

    return props.asMenuItem ? menuItem : iconButton;
};

const CommandEdit = (props: ICommandProps) => {
    const { gridRef, props: gridProps } = useContext(AutoGridContext);
    const [Popup, show, hide, visible] = usePopup(false);

    // TODO: Currently this doesn't take editMode defaulting to 'popup' into account. Only changes if 'popup' is explicitly set
    // TODO: Edit without custom is broken entirely, needs to be fixed
    const customEdit = gridProps.editMode !== 'popup';
    const disabled = props.disabled;
    const key = props.dxProps.key;

    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        if (disabled) return;
        if (customEdit) {
            show();
        } else {
            const row = gridRef.current?.instance.getRowIndexByKey(key);
            if (row !== undefined) gridRef.current?.instance.editRow(row);
        }
    };

    const close = () => {
        hide();
        props.closeParent?.();
    };

    const iconButton = (
        <CommandIconButton
            key={`command-edit-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={disabled}
            style={{ display: props.hidden ? 'none' : undefined }}
        >
            <EditIcon key={`command-edit-aci1-${props.dxProps.key}`} />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={disabled} key={`command-edit-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-edit-lii-${props.dxProps.key}`}>
                <EditIcon key={`command-edit-aci2-${props.dxProps.key}`} color={disabled ? undefined : 'action'} />
            </ListItemIcon>
            <Typography>Rediger</Typography>
        </MenuItem>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            {customEdit && (
                <Popup
                    title='Title'
                    showTitle={false}
                    fullScreen={isMobile}
                    showCloseButton={false}
                    hideOnOutsideClick={false}
                    onHidden={close}
                    animation={undefined}
                >
                    {/* Unmount content when not visible to avoid lookups fwtching data for every row in the grid */}
                    {visible && <PopupForm props={props} hide={close} />}
                </Popup>
            )}
        </>
    );
};

const CommandDelete = (props: ICommandProps) => {
    const { gridRef } = useContext(AutoGridContext);
    const disabled = props.disabled;
    const key = props.dxProps.key;
    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        if (disabled) return;
        const row = gridRef.current?.instance.getRowIndexByKey(key);
        if (row !== undefined) gridRef.current?.instance.deleteRow(row);
    };

    const iconButton = (
        <CommandIconButton
            key={`command-delete-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={disabled}
        >
            <DeleteForeverIcon key={`command-delete-aci1-${props.dxProps.key}`} />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={disabled} key={`command-edit-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-delete-lii-${props.dxProps.key}`}>
                <DeleteForeverIcon
                    key={`command-delete-aci2-${props.dxProps.key}`}
                    color={disabled ? undefined : 'action'}
                />
            </ListItemIcon>
            <Typography>Slet</Typography>
        </MenuItem>
    );

    return props.asMenuItem ? menuItem : iconButton;
};

//#endregion Information
//#region More - Flere handlinger

export interface ICommandMoreProps {
    itemFactories: Array<(dxProps: IDxRenderProps, closeParent: () => void) => JSX.Element>;
}

const CommandMore = (props: ICommandProps<ICommandMoreProps>) => {
    const iconRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = async (e) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev);
    };

    const close = () => {
        setIsOpen(false);
    };

    const onClickAway = () => {
        if (document.getElementsByClassName('dx-overlay-wrapper').length === 0) close();
    };

    const iconButton = (
        <CommandIconButton
            key={`command-more-${props.dxProps.key}`}
            ref={iconRef}
            onClick={onClick}
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <MoreHorizIcon />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem key={`command-more-${props.dxProps.key}`} onClick={onClick} disabled={props.disabled}>
            <ListItemIcon key={`command-more-lii-${props.dxProps.key}`}>
                <MoreHorizIcon />
            </ListItemIcon>
            More
        </MenuItem>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <ClickAwayListener onClickAway={onClickAway}>
                <Popper
                    key={`command-more-menu-${props.dxProps.key}`}
                    anchorEl={iconRef.current}
                    open={isOpen}
                    onMouseLeave={onClickAway}
                    keepMounted
                    placement='bottom'
                    sx={{ backgroundColor: 'white', borderRadius: '5px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }}
                >
                    {/* Span for at kunne give et ID, og det er en komponent der ikke rykker rundt på ting */}
                    {props.options.itemFactories.map((itemFactory, index) => (
                        <span key={`moreCommandItem-${index}`}>{itemFactory(props.dxProps, close)}</span>
                    ))}
                    {/* {props.options.itemFactories.map((itemFactory, index) =>
                    <React.Fragment key={`moreCommandItem-${index}`}>
                    {itemFactory(props.dxProps, close)}
                    </React.Fragment>
                )} */}
                </Popper>
            </ClickAwayListener>
        </>
    );
};

//#endregion More - Flere handlinger
//#region QR Generator

export interface ICommandQR {}

interface ICommandQRData {
    beskrivelse?: string;
}

const CommandQRGenerator = (props: ICommandProps<ICommandQR, ICommandQRData>) => {
    const [open, setOpen] = useState(false);
    const domId = useId();

    const { id } = useEnhed();

    const onClick: React.MouseEventHandler = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const download = () => {
        const svg = document.querySelector(`#${domId}`);
        if (svg == null) return;
        downloadSVGToUser(svg, `Caretaker QR ${domId}`);
    };

    const print = () => {
        printJS(`${domId}-parent`, 'html');
    };

    const iconButton = (
        <CommandIconButton
            key={`command-info-${props.dxProps.key}`}
            onClick={onClick}
            className='auto-grid-icon-button'
        >
            <QrCode2Icon />
        </CommandIconButton>
    );

    const menuItem = (
        <Restricted permissions={[UserPermissions.Drift_QR]} unauthorizedProps={{ disabled: true }}>
            <MenuItem key={`command-info-${props.dxProps.key}`} onClick={onClick}>
                <ListItemIcon>
                    <QrCode2Icon />
                </ListItemIcon>
                <Typography>QR Kode</Typography>
            </MenuItem>
        </Restricted>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <Popup
                visible={open}
                onHiding={() => setOpen(false)}
                showCloseButton={true}
                hideOnOutsideClick={true}
                title={`QR KODE`}
                height={550}
                width={300}
            >
                <CenterCenterBox>
                    <Stack spacing={2}>
                        <div id={`${domId}-parent`}>
                            <div
                                style={{
                                    width: '256px',
                                }}
                            >
                                <QRCode
                                    id={'id'}
                                    value={`${window.location.origin}/Drift/Rul/${props.dxProps.data.id}`}
                                />
                            </div>
                            <div>
                                <h4 style={{ margin: '4px', marginLeft: 0 }}>{id}</h4>
                                <p style={{ margin: '4px', marginLeft: 0 }}>{props.dxProps.data.beskrivelse}</p>
                            </div>
                        </div>
                        <Button onClick={download}>Hent QR kode</Button>
                        <Button onClick={print}>Print QR kode</Button>
                    </Stack>
                </CenterCenterBox>
            </Popup>
        </>
    );
};

//#endregion QR Generator
//#region Report error

export interface ICommandReportError {}

const CommandReportError = (props: ICommandProps<ICommandReportError>) => {
    const onClick = () => undefined;

    const iconButton = (
        <CommandIconButton
            key={`command-report-error-${props.dxProps.key}`}
            onClick={onClick}
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <InfoIcon key={`command-info-aci1-${props.dxProps.key}`} color='primary' />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={props.disabled} key={`command-report-error-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-report-error-lii-${props.dxProps.key}`}>
                <WarningIcon key={`command-report-error-aci2-${props.dxProps.key}`} />
            </ListItemIcon>
            <Typography>Helpdesk</Typography>
        </MenuItem>
    );

    return props.asMenuItem ? menuItem : iconButton;
};

//#endregion Report error
//#region Roll

export interface ICommandRollProps {}

export interface ICommandRollData {
    beskrivelse: string;
    skemakode?: number | string | null;
    close: VoidFunction;
}

// Became obsolete when we moved to the new popup
export const CommandRoll = (props: ICommandProps<ICommandSkema, ICommandSkemaData>) => {
    return CommandSkemaPopup({
        ...props,
        options: { ...props.options, noSkema: props.dxProps.data.skemakode == null },
    });

    // Lots of redundant code was here
};

//#endregion Roll
//#region Timeline Popup

export interface ICommandTimelinePopupProps {}

export interface ICommandTimelinePopupData {}

export const CommandTimelinePopup = (props: ICommandProps<ICommandTimelinePopupProps, ICommandTimelinePopupData>) => {
    const [open, setOpen] = useState(false);

    const url = useCommandUrl(props);
    const onClick = () => setOpen(true);

    const iconButton = (
        <CommandIconButton
            key={`command-timeline-${props.dxProps.key}`}
            onClick={onClick}
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <ReportIcon />
        </CommandIconButton>
    );

    const menuItem = (
        <Restricted permissions={[UserPermissions.Drift_Adhocjournal]} unauthorizedProps={{ disabled: true }}>
            <MenuItem disabled={props.disabled} key={`command-roll-${props.dxProps.key}`} onClick={onClick}>
                <ListItemIcon>
                    <ReportIcon />
                </ListItemIcon>
                Timeline
            </MenuItem>
        </Restricted>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            {open && (
                <InternalCommandTimelinePopup id={props.dxProps.data.id} url={url} open={open} setOpen={setOpen} />
            )}
        </>
    );
};

const InternalCommandTimelinePopup = ({
    id,
    url,
    open,
    setOpen,
}: {
    id: number;
    url: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [data, isLoading, isError] = useFetch<ITimelineItem[]>(`${url}/Lookup/Journal?aktivitetssys=${id}`);
    const { enqueueSnackbar } = useCentralizedSnackbar();

    const selectedId = data?.find((d) => d.id)?.id;

    useEffect(() => {
        if (isError || (!isLoading && !selectedId)) {
            enqueueSnackbar('Ingen journal fundet', { variant: 'warning' });
            setOpen(false);
        }
    }, [enqueueSnackbar, isError, isLoading, selectedId, setOpen]);

    if (isError || !selectedId || isLoading) return <></>;

    return (
        <TimelinePopup
            open={open}
            setOpen={setOpen}
            data={data}
            selectedId={selectedId}
            urlWithoutId={`${url}/Lookup/Journal/Skema?id=`}
        />
    );
};

//#endregion Timeline Popup
//#region Incident
export interface ICommandIncidentProps {}

export interface ICommandIncidentData {
    beskrivelse: string;
    skemakode?: number | string | null;
    close: VoidFunction;
}

const Get_IncidentPopupRender = (props: ICommandProps<ICommandIncidentProps, ICommandIncidentData>) => (
    <IncidentPopupRender {...props} />
);

const IncidentPopupRender = (props: ICommandProps<ICommandRollProps, ICommandRollData>) => {
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const { gridRef } = useContext(AutoGridContext);
    const baseUrl = useCommandUrl(props);
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.dxProps.data.close();
        const data = new FormData(e.currentTarget);
        const response = await Api.post(`${baseUrl}/Udfald/${props.dxProps.key}`, data);
        if (!Api.ok(response)) {
            enqueueSnackbar('Der skete en fejl', { variant: 'error' });
        } else {
            enqueueSnackbar('Gemt', { variant: 'success' });
            gridRef.current?.instance.refresh(true);
        }
    };
    const handleCancel = () => {
        props.dxProps.data.close();
    };
    return (
        <Scrollable>
            <Box sx={{ padding: 0, height: 'auto' }} component='form' onSubmit={handleSubmit}>
                <button type='submit' disabled style={{ display: 'none' }} aria-hidden='true' />
                <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <FormTextField
                            name='note'
                            placeholder='Fritekst'
                            multiline
                            minRows={2}
                            sx={{ paddingInline: '13px' }}
                        ></FormTextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography pl='13px'>{'Vil du gemme? (gemmer kun i journal)'}</Typography>
                    </Grid>
                    <Grid item xs={6} alignItems='end'>
                        <BottomRightBox>
                            <Button
                                type='submit'
                                color='success'
                                variant='contained'
                                className='udfør-popup-buttons udfør-popup-ja'
                                sx={{ textTransform: 'none', boxShadow: 'none' }}
                            >
                                Gem
                            </Button>
                        </BottomRightBox>
                    </Grid>
                    <Grid item xs={6} alignItems='start'>
                        <BottomLeftBox>
                            <Button
                                onClick={handleCancel}
                                variant='contained'
                                className='udfør-popup-buttons udfør-popup-nej'
                                sx={{ textTransform: 'none', boxShadow: 'none' }}
                            >
                                Annuller
                            </Button>
                        </BottomLeftBox>
                    </Grid>
                </Grid>
            </Box>
        </Scrollable>
    );
};

const CommandIncident = (props: ICommandProps<ICommandRollProps, ICommandRollData>) => {
    const [open, setOpen] = useState(false);
    props.dxProps.data.close = () => {
        handleOnHiding();
    };

    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleOnHiding = () => {
        setOpen(false);
    };

    const iconButton = (
        <CommandIconButton
            key={`command-incident-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <ReportIcon key={`command-incident-aci1-${props.dxProps.key}`} />
        </CommandIconButton>
    );

    const menuItem = (
        <Restricted permissions={[UserPermissions.Drift_Adhocjournal]} unauthorizedProps={{ disabled: true }}>
            <MenuItem disabled={props.disabled} key={`command-roll-${props.dxProps.key}`} onClick={onClick}>
                <ListItemIcon>
                    <ReportIcon />
                </ListItemIcon>
                Indberet hændelse
            </MenuItem>
        </Restricted>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <Popup
                visible={open}
                contentRender={() => Get_IncidentPopupRender(props)}
                showCloseButton
                onHiding={handleOnHiding}
                title='Indberet hændelse'
                width='276px'
                height='280px'
            >
                {' '}
            </Popup>
        </>
    );
};

//#endregion Incident

//#region Skema

export interface ICommandSkema {
    noSkema?: boolean;
}

export interface ICommandSkemaData {
    skemakode: number | string | null;
    beskrivelse: string;
}

//#region As screen

const CommandSkema = (props: ICommandProps<ICommandSkema, ICommandSkemaData>) => {
    const { navigate } = useHistoryNavigate();
    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = (e) => {
        e.stopPropagation();
        navigate(`/Drift/Skema/${props.dxProps.data.id}`);
    };

    const iconButton = (
        <CommandIconButton
            key={`command-skema-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <PlaylistAddCheckIcon key={`command-skema-aci1-${props.dxProps.key}`} color='success' />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem disabled={props.disabled} key={`command-skema-${props.dxProps.key}`} onClick={onClick}>
            <ListItemIcon key={`command-skema-lii-${props.dxProps.key}`}>
                <PlaylistAddCheckIcon key={`command-skema-aci2-${props.dxProps.key}`} color='success' />
            </ListItemIcon>
            <Typography>Skema</Typography>
        </MenuItem>
    );

    return <>{props.asMenuItem ? menuItem : iconButton}</>;
};

//#endregion As screen
//#region In Popup

interface ForceFotoReturnType {
    defaultValue: string;
    descriptionText: string;
    header: string;
    id: number;
    value: string;
}

export interface CommandSkemaPopupRenderProps {
    id: number;
    handleSubmit(data: FormData, submitType: IAutoFormSubmitType): void;
    handleCancel(): void;
    props: WithOptional<ICommandProps<ICommandSkema, ICommandSkemaData>, 'dxProps' | 'closeParent' | 'options'>;
}

/**
 * Opens skema in a popup. As this same render function is used in the Drift rul screen, props type has been made more optional
 */
export const CommandSkemaPopupRender = ({ id, handleSubmit, handleCancel, props }: CommandSkemaPopupRenderProps) => {
    const baseUrl = useCommandUrl(props);
    const [data, loading, isError] = useFetch<IGrupper[]>(`${baseUrl}/Skema/${id}`);
    const [formStep, setFormStep] = useState<'skema' | 'confirmRoll'>(props.options?.noSkema ? 'confirmRoll' : 'skema');
    const formDataRef = useRef<FormData>(new FormData());
    const [forceFotoList, setforceFotoList] = useState<ForceFotoReturnType[]>([]);
    const [isUploaded, setIsUploaded] = useState<boolean[]>([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const { hasPermissions } = usePermissions();

    const handleSkemaSubmit = async (formData: FormData, submitType: IAutoFormSubmitType) => {
        if (submitType !== 'submit') {
            // Move on to confirmation
            handleSubmit(formData, submitType);
            return;
        }
        formDataRef.current = formData;

        const pairs: IDictionary<FormDataEntryValue, string> = {};
        let changes = false;
        formData.forEach((value, key) => {
            pairs[key] = value;
            // Check if the value has changed
            if (data.find((g) => g.items.find((i) => i.id === key && i.value !== value))) changes = true;
        });

        const formDataNew = new FormData();
        formDataNew.set('pairs', JSON.stringify(pairs));

        if (!changes && hasPermissions(UserPermissions.Drift_Fasttrack)) {
            handleSubmit(formDataNew, submitType);
            return;
        }
        setFormStep('confirmRoll');

        // This response should be the list of titles where a foto is forces
        const response = await Api.post(`${baseUrl}/forcefoto/${id}`, formDataNew);
        setforceFotoList(response.data as ForceFotoReturnType[]);
        setIsUploaded(new Array((response.data as ForceFotoReturnType[]).length).fill(false));
    };

    const handleSkemaCancel = () => {
        handleCancel();
    };

    const handleConfirmSubmit = (formData: FormData, submitType: IAutoFormSubmitType) => {
        // Prevent multiple clicks
        if (submitClicked) {
            console.log('already clicked');
            return;
        } else setSubmitClicked(true);

        // Api expects skema data in a dictionary keyed with "pairs"
        const pairs: IDictionary<FormDataEntryValue, string> = {};
        formDataRef.current.forEach((value, key) => {
            pairs[key] = value;
        });

        formData.set('pairs', JSON.stringify(pairs));

        handleSubmit(formData, submitType);
    };

    const handleConfirmCancel = () => {
        if (data.length > 0) {
            setFormStep('skema');
        } else {
            handleCancel();
        }
    };

    if (id === undefined || isError)
        return (
            <ScreenContainer>
                <Typography>Der skete en fejl. Måske findes dette skema ikke?</Typography>
            </ScreenContainer>
        );

    if (loading)
        return (
            <Container>
                <Scrollable
                    key='skema-scrollable'
                    header={
                        <Typography sx={{ paddingTop: '10px' }} align='center' variant='h4'>
                            Skema {id}
                        </Typography>
                    }
                >
                    <LoadingScreen />
                </Scrollable>
            </Container>
        );

    return (
        <Container sx={{ height: '100%', padding: 0 }}>
            <div
                style={{ height: '100%', display: data.length === 0 || formStep === 'confirmRoll' ? 'none' : 'unset' }}
            >
                <AutoForm
                    key='skema-form'
                    grupper={data}
                    onSubmit={handleSkemaSubmit}
                    onCancel={handleSkemaCancel}
                    sx={{ height: '100%' }}
                    submitPosition='footer'
                    buttons={
                        hasPermissions(UserPermissions.Drift_Fasttrack)
                            ? ['submit', 'cancel']
                            : ['submit', 'save', 'cancel']
                    }
                    keepUnchanged
                />
            </div>
            {(data.length === 0 || formStep === 'confirmRoll') && (
                <AutoForm
                    key='confirm-roll-form'
                    grupper={[
                        {
                            id: 0,
                            items: [
                                {
                                    id: 'note',
                                    label: hasPermissions(UserPermissions.OK) ? 'Udkald' : 'Note',
                                    type: ItemTypes.String,
                                    required: false,
                                    placeholder: hasPermissions(UserPermissions.OK)
                                        ? 'Beskriv kort hvad udkaldet drejede sig om:'
                                        : undefined,
                                },
                                // add fileuploader for every forced foto
                                ...forceFotoList.map((item, i) => ({
                                    id: 'files',
                                    label: item.header,
                                    type: ItemTypes.File,
                                    required: false,
                                    props: {
                                        fileProps: {
                                            accept: 'image/*',
                                            labelText: 'Krævet*',
                                            multiple: true,
                                            onValueChanged(e: ValueChangedEvent) {
                                                if ((e.value?.length ?? 0) > 0) {
                                                    isUploaded[i] = true;
                                                    if (!isUploaded.includes(false)) setIsUploaded([...isUploaded]);
                                                } else {
                                                    isUploaded[i] = false;
                                                }
                                            },
                                        },
                                    },
                                })),
                                {
                                    id: 'files',
                                    label: isUploaded.length > 0 ? 'Andre billeder' : 'Billeder',
                                    type: ItemTypes.File,
                                    required: false,
                                    props: {
                                        fileProps: {
                                            accept: 'image/*',
                                            multiple: true,
                                        },
                                    },
                                },
                            ],
                        },
                    ]}
                    onSubmit={handleConfirmSubmit}
                    onCancel={handleConfirmCancel}
                    sx={{ height: '100%' }}
                    submitPosition='footer'
                    buttons={isUploaded.includes(false) ? ['disabledSubmit', 'cancel'] : ['submit', 'cancel']}
                />
            )}
        </Container>
    );
};

const Get_CommandSkemaPopupRender = (props: CommandSkemaPopupRenderProps) => <CommandSkemaPopupRender {...props} />;

const CommandSkemaPopup = (props: ICommandProps<ICommandSkema, ICommandSkemaData>) => {
    const baseUrl = useCommandUrl(props);
    const [open, setOpen] = useState(false);
    const { gridRef } = useContext(AutoGridContext);
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const disabled = props.disabled || props.dxProps.data.skemakode == null;
    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setOpen(true);
    };

    const close = () => {
        setOpen(false);
        props.closeParent?.();
    };

    //#region Event handlers

    const handleSubmit = async (data: FormData, submitType: IAutoFormSubmitType) => {
        const rul = submitType === 'submit';
        const url = rul
            ? `${baseUrl}/SkemaRul/${props.dxProps.data.id}`
            : `${baseUrl}/Skema/${props.dxProps.data.id}?rul=false`;

        let length = 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _ of data.entries()) {
            length += 1;
        }

        if (!rul && length === 0) {
            console.log('No changes');
            enqueueSnackbar('Ingen ændringer foretaget');
            close();
            return;
        }

        const response = await Api.post(url, data);
        if (Api.ok(response)) {
            enqueueSnackbar(rul ? 'Udført' : 'Skemaet blev gemt', { variant: 'success' });
        } else if (rul && response.status === 403) {
            enqueueSnackbar('Denne daglige opgave er udført i dag.', { variant: 'warning' });
        } else {
            enqueueSnackbar('Noget gik galt', { variant: 'error' });
        }
        gridRef.current?.instance.refresh(false);

        close();
    };

    const handleCancel = () => {
        close();
    };

    //#endregion Event handlers

    const iconButton = props.options.noSkema ? (
        <Restricted permissions={UserPermissions.Drift_Rul} unauthorizedProps={{ disabled: true }}>
            <CommandIconButton
                key={`command-roll-${props.dxProps.key}`}
                onClick={onClick}
                //component="span"
                className={`skemabtn ${props.dxProps.key} auto-grid-icon-button`}
                disabled={props.disabled}
                style={{ display: props.hidden ? 'none' : undefined }}
            >
                <CheckIcon color='success' />
            </CommandIconButton>
        </Restricted>
    ) : (
        <CommandIconButton
            key={`command-skema-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className={`skemabtn ${props.dxProps.key} auto-grid-icon-button`}
            disabled={disabled}
            style={{ display: props.hidden ? 'none' : undefined }}
        >
            <PlaylistAddCheckIcon key={`command-skema-aci1-${props.dxProps.key}`} color='success' />
        </CommandIconButton>
    );

    const menuItem = props.options.noSkema ? (
        <MenuItem
            disabled={props.disabled}
            key={`command-roll-${props.dxProps.key}`}
            className={`skemabtn ${props.dxProps.key}`}
            onClick={onClick}
        >
            <ListItemIcon>
                <CheckIcon color='success' />
            </ListItemIcon>
            Udfør
        </MenuItem>
    ) : (
        <MenuItem
            disabled={disabled}
            key={`command-skema-${props.dxProps.key}`}
            className={`skemabtn ${props.dxProps.key}`}
            onClick={onClick}
        >
            <ListItemIcon key={`command-skema-lii-${props.dxProps.key}`}>
                {props.options.noSkema ? (
                    <CheckIcon color='success' />
                ) : (
                    <PlaylistAddCheckIcon
                        key={`command-skema-aci2-${props.dxProps.key}`}
                        color={disabled ? undefined : 'success'}
                    />
                )}
            </ListItemIcon>
            <Typography>{props.options.noSkema ? 'Udfør' : 'Skema'}</Typography>
        </MenuItem>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <Popup
                visible={open}
                hideOnOutsideClick={false}
                title={props.dxProps.data.beskrivelse}
                showCloseButton={false}
                width='80%'
                height='80%'
                style={{ padding: '10px' }}
                contentRender={() =>
                    Get_CommandSkemaPopupRender({
                        id: props.dxProps.data.id,
                        handleSubmit,
                        handleCancel,
                        props,
                    })
                }
                fullScreen={isMobile}
            />
        </>
    );
};

//#endregion In Popup

//#endregion Skema
//#region Upload

export interface ICommandUploadProps {}

const CommandUpload = (props: ICommandProps<ICommandUploadProps>) => {
    const baseUrl = useCommandUrl(props);
    const { gridRef } = useContext(AutoGridContext);
    const [isVisible, setIsVisible] = useState(() => false);

    const onClick: React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> = (e) => {
        e.stopPropagation();
        setIsVisible(true);
    };

    const iconButton = (
        <CommandIconButton
            key={`command-upload-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <FileUploadIcon key={`command-upload-fui1-${props.dxProps.key}`} color='primary' />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem key={`command-upload-${props.dxProps.key}`} onClick={onClick} disabled={props.disabled}>
            <ListItemIcon key={`command-upload-lii-${props.dxProps.key}`}>
                <FileUploadIcon key={`command-upload-fui2-${props.dxProps.key}`} color='primary' />
            </ListItemIcon>
            Tilføj billede
        </MenuItem>
    );

    const overrideCloseFunction = (anyUploads: boolean) => {
        if (anyUploads) gridRef.current?.instance.refresh(false);
        props.closeParent?.();
        setIsVisible(false);
    };

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <DragDropUploaderPopup
                key={`command-upload-uploader-${props.dxProps.key}`}
                uploadUrl={Api.createUrl(`${baseUrl}/Upload/${props.dxProps.key}`)}
                popupOptions={{
                    visible: isVisible,
                    overrideCloseFunction: overrideCloseFunction,
                    hideOnOutsideClick: true,
                    height: '50%',
                }}
                uploaderOptions={{
                    multiple: true,
                    ...props.uploadProps,
                }}
            />
        </>
    );
};

//#endregion Upload

const CommandFlytMaaler = (props: ICommandProps) => {
    const [Popup, show, hide] = usePopup(false);
    const baseUrl = useCommandUrl(props);
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const id = props.dxProps.data.id;
    const { gridRef } = useContext(AutoGridContext);

    const onClick = () => {
        show();
    };

    const onEnhedClick = async (e: ItemClickEvent) => {
        console.log(props.dxProps);
        const result = await Api.put(`${baseUrl}/FlytMaaler?id=${id}&enhedsys=${e.itemData.id}`);
        if (!Api.ok(result)) {
            enqueueSnackbar('Der skete en fejl', { variant: 'error' });
        }
        hide();
        gridRef.current?.instance.refresh(true);
        return {};
    };

    const iconButton = (
        <CommandIconButton
            key={`command-upload-${props.dxProps.key}`}
            onClick={onClick}
            //component="span"
            className='auto-grid-icon-button'
            disabled={props.disabled}
        >
            <MoveDownIcon key={`command-upload-fui1-${props.dxProps.key}`} color='primary' />
        </CommandIconButton>
    );

    const menuItem = (
        <MenuItem key={`command-upload-${props.dxProps.key}`} onClick={onClick} disabled={props.disabled}>
            <ListItemIcon key={`command-upload-lii-${props.dxProps.key}`}>
                <MoveDownIcon key={`command-upload-fui2-${props.dxProps.key}`} color='primary' />
            </ListItemIcon>
            Flyt måler
        </MenuItem>
    );

    return (
        <>
            {props.asMenuItem ? menuItem : iconButton}
            <Popup
                title='Vælg ny enhed til måleren'
                showCloseButton={true}
                hideOnOutsideClick={true}
                onHidden={hide}
                animation={undefined}
            >
                <>
                    <ExtendedEnhedList listOnly alternativeOnClick={onEnhedClick} />
                </>
            </Popup>
        </>
    );
};

//#endregion Renders

export enum CommandRenderKeys {
    Info = 'command-info',
    More = 'command-more',
    QRGenerator = 'command-qr-generator',
    ReportError = 'command-report-error',
    Roll = 'command-roll',
    Skema = 'command-skema',
    SkemaPopup = 'command-skema-popup',
    Upload = 'command-upload',
    Incident = 'command-incident',
    Edit = 'command-edit',
    Delete = 'command-delete',
    TimelinePopup = 'command-timeline-popup',
    FlytMaaler = 'command-flyt-maaler',
}

const ICommandRenders: IDictionary<(props: ICommandProps) => JSX.Element> = {
    [CommandRenderKeys.Info]: CommandInfo,
    [CommandRenderKeys.More]: CommandMore,
    [CommandRenderKeys.QRGenerator]: CommandQRGenerator,
    [CommandRenderKeys.ReportError]: CommandReportError,
    [CommandRenderKeys.Roll]: CommandRoll,
    [CommandRenderKeys.Skema]: CommandSkema,
    [CommandRenderKeys.SkemaPopup]: CommandSkemaPopup,
    [CommandRenderKeys.Upload]: CommandUpload,
    [CommandRenderKeys.Incident]: CommandIncident,
    [CommandRenderKeys.Edit]: CommandEdit,
    [CommandRenderKeys.Delete]: CommandDelete,
    [CommandRenderKeys.TimelinePopup]: CommandTimelinePopup,
    [CommandRenderKeys.FlytMaaler]: CommandFlytMaaler,
};

/**
 *
 * @author Asbjørn Rysgaard Eriksen <are@caretaker.dk>
 * @param key A magic string indicating which command to return. They can be found in the CommandRenderKeys enum
 * @param asMenuItem Boolean indicating wether the command is shown as its own button or an item in a "more" list
 * @param options The options given to the individual command
 * @returns a renderfunction returning a JSX.Element to show
 */
function GetCommandRender<TOptions = unknown, TData = unknown>(
    key: string,
    zCommand: ZCommand,
    options: TOptions | {} = {}
): undefined | ((dxProps: IDxRenderProps<TData>) => JSX.Element) {
    const RenderElement = ICommandRenders[key];
    if (RenderElement === undefined) return undefined;

    // closeParent bruges når funktion kaldes i "More"
    return (dxProps: IDxRenderProps<TData>, closeParent?: () => void) => (
        <RenderElement dxProps={dxProps} options={options} closeParent={closeParent} {...zCommand} />
    );
}

export default GetCommandRender;
