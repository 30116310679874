import { NavLink, NavLinkProps } from 'react-router-dom';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';

/** Component version of `useCTNavigate`. 
 *
 * For documentation, refer to `useCTNavigate` and https://reactrouter.com/en/main/components/nav-link
 *
 * Note that links cannot navigate relative to the current history. For that, use `navigate(number)`
 */
const CTNavLink = ({ children, to, ...props }: NavLinkProps) => {
    const id = useEnhedId();
    if (typeof to === "string" && !/^\/(-?\d+)/.test(to)) {
        to = `/${id}${to}`;
    }
    return (
        <NavLink to={to} {...props}>
            {children}
        </NavLink>
    );
};

export default CTNavLink;
