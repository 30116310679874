import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { CSSProperties, Suspense, useState } from 'react';
import ContextPicker from './context/context-picker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import OverviewMap from './overview.map';
import OverviewTree from './overview.tree';
import { useCTEffect } from '../../shared/hooks/use-ct';
import Button from 'devextreme-react/button';
import useHistoryNavigate from '../../shared/hooks/extended-hooks/use-history-navigate';
import LoadingScreen from '../loading';

const handleStyle: CSSProperties = {
    width: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
};

const OverviewLayout = () => {
    const params = useParams();
    const { navigate } = useHistoryNavigate();
    const getNewShowBackButton = () => {
        return (
            !window.location.pathname.toLowerCase().toLowerCase().includes('tiles') &&
            !window.location.pathname.toLowerCase().toLowerCase().includes('drift')
        );
    };
    const getBackUrl = () => {
        let path = window.location.pathname
            .toLowerCase()
            .split('/')
            .filter((x) => x !== '');
        if (path.length > 2) {
            path = path.slice(0, -1);
        }
        return '/' + path.join('/') + window.location.search;
    };
    const [showBackButton, setShowBackButton] = useState(getNewShowBackButton());
    useCTEffect(() => {
        setShowBackButton(getNewShowBackButton());
    }, [params]);
    return (
        <PanelGroup direction='horizontal'>
            <Panel defaultSize={25}>
                <div className='h-full w-full flex flex-col'>
                    {/* <div className='flex-none w-full'>
                        <ContextPicker />
                    </div> */}
                    <div className='flex-1 overflow-auto' style={{ paddingTop: '5px' }}>
                        <OverviewTree />
                    </div>
                </div>
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel defaultSize={25}>
                <OverviewMap />
            </Panel>
            <PanelResizeHandle style={handleStyle} className='flex justify-center items-center'>
                <MoreVertIcon color='primary' />
            </PanelResizeHandle>
            <Panel>
                <Suspense fallback={<LoadingScreen />}>
                    {showBackButton ? (
                        <div style={{ marginTop: '6px', height: '100%' }}>
                            <div style={{ width: '100%', paddingBottom: '6px', display: 'flex' }}>
                                <NavLink to={getBackUrl()}>
                                    <Button icon={'arrowleft'} style={{ display: 'flex', width: '36px' }} />
                                </NavLink>
                            </div>
                            <div style={{ marginTop: '-36px', height: 'calc(100% - 6px)', overflow: 'scroll' }}>
                                <Outlet />
                            </div>
                        </div>
                    ) : (
                        <div style={{ paddingTop: '8px', height: '100%', overflow: 'scroll' }}>
                            <Outlet />
                        </div>
                    )}
                </Suspense>
            </Panel>
        </PanelGroup>
    );
};

export default OverviewLayout;
