import { ChangeEvent, RefObject } from 'react';
import { IDictionary, IntRange, PropsOf, primitive } from '../../../shared/utils/types';
import { SxProps, Theme } from '@mui/material/styles';

import { FieldDataChangedEvent } from 'devextreme/ui/form';
import FileUploader from 'devextreme-react/file-uploader';
import { IComboConditionValue } from './form-items';
import { IFile } from '../../file-displaying/common';

//#region internal // Only directly used by form components, not consumers

// TODO: Fix any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IInputProps<T = any> {
    label?: string;
    required?: boolean;
    disabled?: boolean;
    id: string;
    placeholder?: T;
    defaultValue?: T;
    name?: string;
    autoComplete?: string;
    helperText?: string;
    onChange(e: ChangeEvent): void;
}

export interface IValidation {
    showError: boolean;
    isValid: boolean;
    setValid(valid: boolean): void;
}

export interface IItemProps<T extends TItemValue = TItemValue> {
    item: IItem<T>;
    generalInputProps: IInputProps;
    validation: IValidation;
    scrollRef: RefObject<HTMLDivElement>;
}

export interface IExtraFormData {
    name: string;
    value: string | Blob;
    filename?: string;
}

//#endregion internal

export type IAutoFormSubmitType = 'save' | 'submit' | 'ref';

export interface IAutoFormProps {
    /**
     * Show edit button to switch to edit state
     * @default true
     */
    allowEditing?: boolean;
    /**
     * Edit form initially
     * @default true
     */
    editing?: boolean;
    /** Extra files to display in a filegrid underneath the form */
    files?: IFile[];
    /** Data to append to the form on submit */
    formData?: IExtraFormData[];
    /** The form's input fields */
    grupper: IGrupper[];
    /** Callback for form being canceled */
    onCancel(): void | Promise<void>;
    /** Callback for submitting. submitType indicates how the submit was triggered. submitType = 'ref' means form was submitted through an imperative handle */
    onSubmit(data: FormData, submitType: IAutoFormSubmitType): void | Promise<void>;
    /** Array of which buttons to use */
    buttons?: Array<'save' | 'cancel' | 'help' | 'submit' | 'service' | 'disabledSubmit'>;
    /**
     * Amount of padding (in pixels) to add to the bottom of the grid used to display form items.
     * This is necessary if the form is shown in a height-restricted container, as part of the last form item is otherwise hidden for unknown reasons
     *
     * if true: defaults to 120px
     * @default false
     */
    paddingBottom?: boolean | number;
    /**
     * A setState function getting called on first form render, giving parents access to form.submit(), handleCancel, and handleHelp
     */
    submitPosition?: 'header' | 'footer' | 'none';
    /** noValidate */
    noValidate?: boolean;
    onFieldDataChanged?: (e: { e: FieldDataChangedEvent; egenskaber?: boolean | undefined }) => void;
    /** SxProp for the form */
    sx?: SxProps<Theme>;
    /** Function for to adjust screensize */
    colCount?: number;
    /** Bool for using dx form */
    useDxForm?: boolean;
    /** Labellocation. Possibly a dx prop? */
    labelLocation?: 'left' | 'right' | 'top';
    /** Position label relative to input */
    labelPosition?: 'left' | 'top';
    /** LabelMode */
    labelMode?: 'static' | 'floating' | 'hidden' | 'outside';
    /** keep all data. Dont remove ungchanged data from submit */
    keepUnchanged?: boolean;
    radioUrl?: string;
    id?: string;
    autoSubmit?: boolean;

    /* Determines styling of components.
     * @default AutoFormStyle.Default
     */
    style?: AutoFormStyle;
    service?: boolean;
}

export enum AutoFormStyle {
    Default = 'default',
    Compact = 'compact',
}

export interface IAutoFormHandle {
    submit(): void | Promise<void>;
    cancel(): void;
    help(event: unknown): void;
    allValid?: boolean;
    element: HTMLFormElement;
}

/**
 * Enum for auto-form item types
 */
export enum ItemTypes {
    /** No input. Other options still apply though. Can fx be used to create a header */
    NoInput = 'no-input',

    /** A one-line text input */
    String = 'string',
    /** A multi-line text input */
    Block = 'block',
    /** A multi-line html / rich text editor */
    HtmlBlock = 'html-block',
    /** An integer input */
    Number = 'number',
    /** A floating point input */
    Decimal = 'decimal',
    /** A date input */
    Date = 'date',
    /** A radio Button Input */
    Choice = 'choice',
    /** A fileuploader. Doesn't currently have a lot of customization */
    File = 'file',

    /** A conditional input, with 2 radio buttons, and a multi-line text input if the first radio option is chosen */
    Combo_ConditionalBlock = 'combo-conditional-block',

    /** A Combination of radiobuttons and a textfield */
    Combined = 'combined',
    /** lookup or dropdown */
    Lookup = 'lookup',
}

export type TItemValue = primitive | IComboConditionValue;
export interface IItem<T extends TItemValue = TItemValue> {
    /** A unique ID, also used as the formitems name */
    id: string;
    /** Label for the input */
    label?: string | null;
    /** Label position relative to input */
    labelPosition?: 'top' | 'left';
    /** More detailed description displayed below the label */
    description?: string | null;
    /** Default value for the input */
    value?: T | null;
    /** Placeholder when nothing is typed */
    placeholder?: T | null;
    /** Which input to display */
    type?: ItemTypes | string | null;
    /** Choices for the inputs with limited choices */
    choices?: Array<{
        /** Id submitted with the form */
        id: string | number;
        /** Display value */
        value: string | number;
    }> | null;
    /**
     * autoComplete prop
     *
     * @default 'off'
     */
    autoComplete?: string | null;
    /**
     * Is item required?
     *
     * @default true
     */
    required?: boolean | null;
    /**
     * Is item readonly?
     *
     * @default false
     */
    readonly?: boolean | null;
    /** Sort order for the items. Sorts by ascii order */
    sort?: string | number | null;
    /** extra description for combi */
    descriptionText?: string | null;
    /** extra value for combi */
    valueText?: T | null;
    /** default props */
    defaultProps?: IDictionary;
    /** other props */
    props?: {
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validationRules?: any;
        colSpan?: number | 'max';
        /** add a validationRule for stringLength with the specified number */
        simpleMax?: number;
        /** lookupProps */
        lookupProps?: {
            dataArray?: unknown[];
            url?: string;
            putId?: string;
            putUrl?: string;
            acceptCustomValue?: boolean;
        };
        /** editorOptions */
        // TODO: Fix any type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        editorOptions?: any;
        /** numberprops */
        numberProps?: {
            maxLength?: number;
            seperate?: boolean;
            max?: number;
        };
        radioProps?: {
            url?: string;
            putId?: string;
        };
        /** fileProps */
        fileProps?: PropsOf<FileUploader>;
    };
    /** prev value */
    prev?: T | null;
    /** use layout for service where radiobuttons are alligned horizontally */
    serviceLayout?: boolean;

    /** Set mui's size properties on the item */
    size?: {
        xs?: IntRange<1, 12>;
        sm?: IntRange<1, 12>;
        md?: IntRange<1, 12>;
        lg?: IntRange<1, 12>;
        xl?: IntRange<1, 12>;
    };

    /* Determines styling of component. Takes precedence over Autoform's style prop */
    style?: AutoFormStyle;

    /** Hides the item while editing */
    hideOnEdit?: boolean;

    hideElements?: {
        label?: boolean;
        description?: boolean;
        divider?: boolean;
    };

    /* Name for props thats should always be paired */
    pairName?: string;

    /* Use for layout with db*/
    visibleToggle?: boolean;
}

export interface IFormTriggers {
    allValid: boolean;
    triggerSubmit: VoidFunction;
    triggerCancel: VoidFunction;
    triggerHelp: VoidFunction;
}

export const defaultItem: IItem = {
    id: '',
    required: true,
    readonly: false,
    autoComplete: 'off',
    size: {
        xs: 12,
    },
};

export interface IGrupper {
    id: number;
    label?: string;
    hideOnEdit?: boolean;
    items: Array<IItem>;
}

export const skema84368: IItem[] = [
    {
        id: '1537568',
        label: 'test',
        description: 'tekst..',
        type: 'string',
        required: false,
        readonly: false,
        sort: 'XX',
    },
    {
        id: '1537567',
        label: 'test',
        description: 'Pick one',
        type: 'choice',
        choices: [
            {
                id: '1',
                value: 'Godt',
            },
            {
                id: '2',
                value: 'Bedre',
            },
            {
                id: '3',
                value: 'Super',
            },
            {
                id: '4',
                value: 'Pisse godt',
            },
            {
                id: '5',
                value: 'Mega godt',
            },
        ],
        required: false,
        readonly: false,
        sort: 'Ab',
    },
    {
        id: '1537566',
        label: 'test',
        description: 'int',
        value: '12',
        type: 'number',
        required: false,
        readonly: false,
        sort: 'Ac',
    },
    {
        id: '1537565',
        label: 'test',
        description: 'Dec',
        value: '12.70000000000000',
        type: 'decimal',
        required: false,
        readonly: false,
        sort: 'Ad',
    },
    {
        id: '1537564',
        label: 'test',
        description: 'Datetime',
        type: 'date',
        required: false,
        readonly: false,
        sort: 'Ae',
    },
    {
        id: '1537562',
        label: 'test',
        description: 'Overskrift',
        value: 'Overskrift',
        type: 'no-input',
        required: false,
        readonly: false,
        sort: 'Aa',
    },
];

export const skemaAktivitet: IItem[] = [
    {
        id: 'fritekst',
        label: 'Fritekst',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sodales diam ac elit elementum, nec congue nisi maximus. Nam vitae metus fringilla',
        value: '',
        type: 'block',
        required: false,
        readonly: false,
        sort: 'Aa',
    },
    {
        id: 'billeder',
        label: 'Upload billeder',
        description: undefined,
        value: 'Overskrift',
        type: 'file',
        required: false,
        readonly: false,
        sort: 'AA',
    },
];

export const skemaDate: IItem[] = [
    {
        id: '1537564',
        label: 'test',
        description: 'Datetime',
        type: 'date',
        required: false,
        readonly: false,
        sort: 'Ae',
        value: '2022-06-08',
    },
];

// export interface ISkema {
//     id: number,
//     titel: string,
//     grupper: Array<IGrupper>
// }

export const skemaGrupper: IGrupper[] = [
    {
        id: 1,
        label: 'Test Gruppe 1',
        items: [
            {
                id: '1537724',
                description: 'Navn Pick one',
                value: '2',
                type: 'choice',
                choices: [
                    { id: '1', value: 'Godt' },
                    { id: '2', value: 'Bedre' },
                    { id: '3', value: 'Super' },
                    { id: '4', value: 'Pisse godt' },
                    { id: '5', value: 'Mega godt' },
                ],
                required: false,
                hideElements: { label: true },
                readonly: false,
                sort: 'ac',
            },
        ],
    },
    {
        id: 2,
        label: 'Test Gruppe 2',
        items: [
            {
                id: '1537721',
                description: 'Navn Datetime',
                value: '2022-06-18',
                type: 'date',
                required: false,
                readonly: false,
                sort: 'bc',
            },
            {
                id: '1537722',
                description: 'Navn Dec',
                value: '13.70',
                type: 'decimal',
                required: false,
                readonly: false,
                sort: 'bb',
            },
            {
                id: '1537723',
                description: 'Navn int',
                value: '131',
                type: 'number',
                required: false,
                readonly: false,
                sort: 'ba',
            },
        ],
    },
];

export const skemaCombi: IGrupper[] = [
    {
        id: 3,
        label: 'Test Gruppe 3',
        items: [
            {
                choices: [
                    { id: 'A', value: 'Med' },
                    { id: 'B', value: 'Uden' },
                    { id: 'A', value: 'Med' },
                    { id: 'B', value: 'Uden' },
                    { id: 'A', value: 'Med' },
                    { id: 'B', value: 'Uden' },
                ],

                description:
                    'Navn Kombi, men denne gang er teksten meget længere lkjsdhkjldfhg kjldsh h djfgkj dsf gjkdfg dfg  djkfg df jgjkdf dfkj dfg dfjk ',
                descriptionText: 'DescriptionText',
                id: '1537764',
                readonly: false,
                required: false,
                sort: 'ca',
                type: 'combined',
                value: 'A',
                valueText: 'Test',
            },
        ],
    },
];
