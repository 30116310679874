import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import DataGrid, { Column, Item, SearchPanel, Toolbar } from 'devextreme-react/data-grid';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { NavLink } from 'react-router-dom';
import DxButton from 'devextreme-react/button';
import useQueryParam from '../../shared/hooks/use-query-param';
import { useEffect } from 'react';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import useCTNavigate from '../../shared/hooks/use-ct-navigate';

/** Status for indicator. Uses strings to supports inserting other statuses in the future without breaking compatibility*/
enum RekvisitionStatus {
    Created = 'created',
    Sent = 'sent',
    Received = 'received',
    Done = 'done'
}

/** Rekvisition data. Interface is sparse */
interface RekvisitionData {
    id: number;
    rekvnr: string;
    title: string;
    tilstand: RekvisitionStatus;
}

const RekvisitionStatusIndicator = (cellInfo: { data: RekvisitionData }) => {
    const intStatus = Object.values(RekvisitionStatus).indexOf(cellInfo.data.tilstand);
    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Stepper
                activeStep={intStatus}
                alternativeLabel
                sx={{
                    // Remove margin from labels
                    '& span.MuiStepLabel-label': {
                        mt: 0
                    }
                }}
            >
                <Step key={0}>
                    <StepLabel>Oprettet</StepLabel>
                </Step>
                <Step key={1}>
                    <StepLabel>Sendt</StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel>Modtaget</StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel>Udført</StepLabel>
                </Step>
            </Stepper>
        </Box>
    );
}

const RekvisitionScreen = () => {
    const navigate = useCTNavigate();
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const error = useQueryParam('error');
    const enhedId = useEnhedId();
    const [data, isLoading, isError] = useFetch<RekvisitionData[]>(`/Finans/rekvisition?enhedsys=${enhedId}`);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [error, enqueueSnackbar]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Kunne ikke hente rekvisitioner', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    const updateRekvisition = async (rowData: RekvisitionData) => {
        navigate(`/Rekvisition/${rowData.id}`);
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <Container sx={{
            '& tr.dx-row.dx-data-row > td': {
                verticalAlign: 'middle'
            }
        }}>
            <Typography variant='h4'>Rekvisitioner</Typography>

            <DataGrid
                dataSource={data}
                rowAlternationEnabled
                showBorders
                allowColumnResizing
                width='100%'
                onRowClick={(e) => updateRekvisition(e.data)}
                hoverStateEnabled
            >
                <Toolbar>
                    <Item location='after'>
                        <NavLink to={`/${enhedId}/Rekvisition/Create`}>
                            <DxButton icon='add' text='Ny Rekvisition'></DxButton>
                        </NavLink>
                    </Item>
                    <Item name='searchPanel' />
                </Toolbar>
                <SearchPanel visible={true} />
                <Column dataField='rekvisitionNr' caption='Rekv. Nr.' width='auto' />
                <Column dataField='rekvisitionTekst' caption='Titel' width='auto' />
                <Column dataField='tilstand' caption='Tilstand' cellRender={RekvisitionStatusIndicator} />
            </DataGrid>
        </Container>
    );
};

export default RekvisitionScreen;
